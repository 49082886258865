<template>
  <div>
    <div class="divider my-2">
      <div class="divider-text">
        <span class="devider-class">LATEST FEATURED DAPPS</span>
      </div>
    </div>
    <b-row v-if="!is_mobilesize">
      <b-col v-for="(dapp, index) in dappData" :key="index">
        <div v-if="index<6" class="container pointer" @click="detailView(dapp)">
          <b-img fluid :src="dapp.dapp_logo" alt="(Image 2')" width="100" />
          <div class="bottom-left dapp-name">{{ dapp.dapp_name }}</div>
        </div>
      </b-col>
    </b-row>
    <div class="divider my-2">
      <div class="divider-text"></div>
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BImg } from "bootstrap-vue";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  props: ["dappData"],
  components: {
    BRow,
    BCol,
    BImg,
    // BCarousel,
    // BCarouselSlide,
  },
  methods: {
    detailView(data) {
      const name = data.dapp_name.replace(/[^A-Z0-9]/gi, "-");
      this.$router.push({
        name: "details",
        params: { id: data._id, name: name },
      });
    },
  }
};
</script>

<style  lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 30%;
}
.devider-class {
  height: 16px !important;
  width: 180px;
  border-radius: 25px;
  background: linear-gradient(58.03deg, #f90000 0%, #ffbb00 100%);
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1.1px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  position: relative;
  padding: 6px 6px;
  // top: -8px;
}
.divider .divider-text:before,
.divider .divider-text:after {
  border-top: 1px solid #f90000;
}
.container {
  position: relative;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.dapp-name {
  width: 86%;
  height: 36px;
  background-color: #253137;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -23%;
}
</style>