<template>
  <div>
    <b-row class="text-center">
      <b-col align-self="center" class="mb-2">
        <h2>Discover the world of top trending dapps</h2>
      </b-col>
    </b-row>
    <featured-dapps :dappData="dappData" />
    <b-row class="pb-3">
      <b-col md="3">
        <h3>Rankings by Category</h3>
      </b-col>
    </b-row>
    <b-row v-if="!is_mobilesize">
      <b-col v-for="(tab, index) in tabs" :key="index">
        <b-button
          @click="changeTab(tab,index)"
          pill
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
        >
          <b-row>
            <feather-icon :icon="tab.icon" /><span class="pl-1">{{
              tab.name
            }}</span>
          </b-row>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-form-group>
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="match-height mt-1">
      <b-col lg="12" v-if="current_tab == 0">
        <small>this is All Dapps table</small>
        <dapp-table @searchDapp="search_val=$event" :tableData="dappData" @castVotes="castVote($event)" />
      </b-col>
      <b-col lg="12" v-if="current_tab == 1">
        <small>this is Game table</small>
        <dapp-table @searchDapp="search_val=$event" :tableData="dappData" @castVotes="castVote($event)" />
      </b-col>
      <b-col lg="12" v-if="current_tab == 2">
        <small>this is Marketplaces table</small>
        <dapp-table @searchDapp="search_val=$event" :tableData="dappData" @castVotes="castVote($event)" />
      </b-col>
      <b-col lg="12" v-if="current_tab == 3">
        <small>this is Gambling table</small>
        <dapp-table @searchDapp="search_val=$event" :tableData="dappData" @castVotes="castVote($event)" />
      </b-col>
      <b-col lg="12" v-if="current_tab == 4">
        <small>this is Other table</small>
        <dapp-table @searchDapp="search_val=$event" :tableData="dappData" @castVotes="castVote($event)" />
      </b-col>
    </b-row>
    <!-- <b-row class="pt-5">
      <b-col v-for="n in 4" :key="n" md="3" class="pa-2">
        <b-card>
          <b-row class="pl-2 pointer" @click="linked('all-dapp')">
            <b-avatar size="45">
              <feather-icon size="16" icon="PackageIcon" class="mr-50" />
            </b-avatar>
            <h3 class="pl-3 pt-1">Games</h3>
          </b-row>
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import FeaturedDapps from "./home/FeaturedDapps.vue";
import {
  BRow,
  BCol,
  BButton,
  // BCard,
  // BAvatar,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import { mixinList } from "@/mixins/mixinList";
import DappTable from "./DappTable.vue";
import Ripple from "vue-ripple-directive";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BButton,
    FeaturedDapps,
    DappTable,
    // BCard,
    // BAvatar,
    BFormGroup,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "All" },
        { value: "pending", text: "All Dapps" },
        { value: "active", text: "Exchanges" },
        { value: "rejected", text: "Gambling" },
        { value: "in-review", text: "Marketplaces" },
        { value: "Games", text: "Games" },
        { value: "Other", text: "Other" },
      ],
      tabs: [
        { value: "",name: "All Dapps", icon: "ListIcon" },
        { value: "game", name: "Game", icon: "DribbbleIcon" },
        { value: "marketplace", name: "Marketplace", icon: "ShoppingCartIcon" },
        { value: "gambling", name: "Gambling", icon: "CodesandboxIcon" },
        { value: "other", name: "Other", icon: "CommandIcon" },
      ],
      current_tab: 0,
      query:'?page=1&limit=10&type=&search=',
      new_tab: '',
      search_val: ''
    };
  },
  computed: {
    dappData() {
      return this.$store.state.dapp.dappData;
    },
    all_total() {
      return this.$store.state.dapp.all_total;
    },
  },
  watch: {
    search_val(val) {
      let new_query = `?page=1&limit=10&type=${this.new_tab}&search=${val}`;
      this.getDapps(new_query)
    }
  },
  methods: {
    castVote(val) {
      let new_query = `?page=1&limit=10&type=${this.new_tab}&search=${this.search_val}`;
      let data= {
        id: val,
        query: new_query
      }
      this.$store.dispatch("CAST_VOTE", data);
    },
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
    changeTab(new_tab,index) {
      this.current_tab = index;
      this.new_tab = new_tab.value;

      let new_query = `?page=1&limit=10&type=${new_tab.value}&search=`;
      this.getDapps(new_query);
    },
    getDapps(query) {
      this.$store.dispatch("FETCH_DAPP_DATA", query );
    }
  },
  created() {
    if (!this.$store.state.dapp.clientIP) {
      this.$store.dispatch("FETCH_CLIENT_IP").then(() => {
        this.getDapps(this.query)
      });
    }
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>