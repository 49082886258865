<template>
  <div>
    <b-row class="pb-1">
      <b-col md="9"></b-col>
      <b-col md="3">
        <div class="d-flex justify-content-end col-12 text-end">
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search_value"
              type="search"
              placeholder="Search your Dapp"
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-card v-if="tableData" class="card-company-table">
      <b-progress
        v-if="loading"
        :max="max"
        height="3px"
        :striped="true"
        :animated="true"
      >
        <b-progress-bar :value="value" variant="primary"> </b-progress-bar>
      </b-progress>
      <b-table
        id="table-crypto"
        hover
        :items="tableData"
        responsive
        :fields="!is_mobilesize ? fields:mobile_fields"
        class="mb-0"
        :filter="selected"
        @row-clicked="detailView"
      >
        <!-- company -->
        <template #cell(no)="data">
          <span class="pl-1">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <b-avatar rounded size="45" variant="light-company">
              <b-img-lazy
                center
                fluid
                :src="data.item.dapp_logo"
                alt="avatar img"
                class="image-size"
            /></b-avatar>
            <div>
              <div class="font-weight-bolder pl-1">
                {{
                  !is_mobilesize
                    ? data.item.dapp_name
                    : data.item.dapp_name.slice(0, 8) + ".."
                }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(Category)="data">
          <div class="">
            <b-badge :variant="(data.item.dapp_category == 'game') ? 'success' : (data.item.dapp_category == 'gambling') ? 'primary' : (data.item.dapp_category == 'marketplace') ? 'warning' : 'info'">{{data.item.dapp_category}}</b-badge>
          </div>
        </template>
        <template #cell(BALANCE_ETH)="data">
          <div class="">
            <span class="text-success">{{ data.item.dapp_dex_data.balance }} </span>
          </div>
          <!-- <div class="">
            <span class="text-warning">{{ data.item.usersD }} </span>
          </div> -->
        </template>
        <template #cell(DAILY_USERS)="data">
          <div class="">
            <span class="text-success">{{ data.item.dapp_dex_data.daily_usage }} </span>
          </div>
          <!-- <div class="">
            <span class="text-danger">{{ data.item.volume_USD }} </span>
          </div>
          <div class="">
            <span class="text-warning">{{ data.item.volume_prentage }} </span>
          </div> -->
        </template>
        <template #cell(VOLUME_24H_ETH)="data">
          <div class="">
            <span class="text-success">{{ data.item.dapp_dex_data.volume_24h }} </span>
          </div>
          <!-- <div class="">
            <span class="text-warning">{{ data.item.usersD }} </span>
          </div> -->
        </template>
        <template #cell(VOLUME_7D_ETH)="data">
          <div class="">
            <span class="text-success">{{ data.item.dapp_dex_data.volume_7d }} </span>
          </div>
          <!-- <div class="">
            <span class="text-warning">{{ data.item.usersD }} </span>
          </div> -->
        </template>
        <template #cell(TX_24H)="data">
          <div class="">
            <span class="text-success">{{ data.item.usersU }} </span>
          </div>
          <div class="">
            <span class="text-warning">{{ data.item.usersD }} </span>
          </div>
        </template>
        <template #cell(TX_7D)="data">
          <div class="">
            <span class="text-success">{{ data.item.usersU }} </span>
          </div>
          <div class="">
            <span class="text-warning">{{ data.item.usersD }} </span>
          </div>
        </template>
        <template #cell(vote_count)="data">
          <div class="d-flex align-items-center">
            <b-button
              @click="castVote(data.item)"
              :class="is_mobilesize ? 'button-class' : 'desktop-button'"
              variant="outline-success"
            >
              <div v-if="loading && data.item._id == selectId">
                <b-spinner
                  label="Loading..."
                  class="mr-2 mx-1"
                  style="width: 13px; height: 13px"
                ></b-spinner>
              </div>
              <div v-else>
                🚀 {{ data.item.dapp_vote_count }}
              </div>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="text-center pb-1" v-if="!is_show_pagination > per_page">
        <b-button
          v-if="!loading"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="flat-secondary"
          @click="changePagination"
          class="font-weight-bolder"
        >
          See More
        </b-button>
        <b-spinner
          v-else
          label="Loading..."
          class="mr-2 mx-1"
          style="width: 13px; height: 13px"
        ></b-spinner>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import Ripple from "vue-ripple-directive";
// import SearchCoin from "./SearchCoin"
import {
  BCard,
  BTable,
  BAvatar,
  BImgLazy,
  BButton,
  BSpinner,
  BProgress,
  BProgressBar,
  BFormInput,
  BCol,
  BRow,
  BInputGroup,
  BBadge,
} from "bootstrap-vue";
import numeral from "numeral";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImgLazy,
    BButton,
    BSpinner,
    BProgress,
    BProgressBar,
    BFormInput,
    BCol,
    BRow,
    BInputGroup,
    BBadge,
    // SearchCoin,
  },
  directives: {
    Ripple,
  },
  props: ['tableData'],
  mixins: [mixinList],
  data() {
    return {
      selected: null,
      per_page: 20,
      is_show_pagination: false,
      transProps: {
        // Transition name
        name: "flip-list",
      },
      numeral,
      dayjs,
      relativeTime,
      sortBy: "vote_count",
      sortDesc: true,
      fields: [
        { key: "no", label: "NO" },
        { key: "name", label: "Name" },
        { key: "new", label: "New" },
        { key: "Category", label: "Category" },
        { key: "BALANCE_ETH", label: "BALANCE ETH " },
        { key: "DAILY_USERS", label: "DAILY USERS" },
        { key: "VOLUME_24H_ETH", label: "VOLUME 24H ETH" },
        { key: "VOLUME_7D_ETH", label: "VOLUME 7D ETH" },
        { key: "vote_count", label: "Vote Count" }
      ],
      mobile_fields: [
        { key: "no", label: "NO" },
        { key: "name", label: "Name" },
        { key: "DAILY_USERS", label: "DAILY USERS" },
        { key: "vote_count", label: "Votes" }
      ],
      selectId: null,
      value: 0,
      max: 100,
      windowHeight: window.innerWidth,
      search_value: '',
    };
  },
  watch: {
    search_value(val) {
      this.$emit("searchDapp", val);
    }
  },
  created() {
    this.dayjs.extend(relativeTime);
  },
  computed: {
    loading() {
      return this.$store.state.loaders.loading;
    },
  },
  methods: {
    detailView(data) {
      const name = data.dapp_name.replace(/[^A-Z0-9]/gi, "-");
      this.$router.push({
        name: "details",
        params: { id: data._id, name: name },
      });
    },
    changePagination() {},
    startTimer() {
      let vm = this;
      let timer = setInterval(function () {
        vm.value += 20;
        if (vm.value >= 100) clearInterval(timer);
      }, 100);
    },
    castVote(coin) {
      this.selectId = coin._id;
      this.$emit("castVotes", coin._id);
    },
    isVoted(isVoted) {
      return isVoted ? "success" : "outline-success";
    },
  },
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return null;
      }

      return dayjs(date).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
table#table-crypto .flip-list-move {
  transition: transform 1s;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0rem;
}
.button-class {
  margin: 0;
  width: 75px;
}
.desktop-button {
  margin: 0;
  width: 150;
}
.row-background {
  background-color: #f1f1f1;
}
@media (max-width: 1024px) {
  .image-size {
    max-width: 60% !important;
  }
}
</style>
